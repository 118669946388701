export const subscribe = {
  api: "https://website-api.covenlabs.xyz/email_subscriptions",
  error: "Submission Failed!",
  validation: {
    email: "Invalid Email!",
  },
  required: {
    email: "Email Required!",
  },
};
