import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import "./Email.scss";
import Loader from "../../assets/images/loader.svg";
import { useState } from "react";

const EmailForm = ({
  config,
  btnText,
  btnClass = "",
  inputClass = "",
  className = "",
  modalHandle,
}) => {
  const EmailFormSchema = Yup.object().shape({
    email: Yup.string()
      .email(config.validation.email)
      .required(config.required.email),
  });

  const [error, setError] = useState("");

  const onChange = () => setError("");

  const onSubmit = (values, { resetForm, setSubmitting }) => {
    setSubmitting(true);
    const response = axios.post(config.api, values);

    response
      .then((response) => {
        modalHandle();
        resetForm();
      })
      .catch((err) => {
        console.log(err);
        setError(config.error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  return (
    <>
      <Formik
        initialValues={{
          email: "",
          topic: "NEWSLETTER",
        }}
        validationSchema={EmailFormSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched, isSubmitting, values }) => (
          <Form className="emailform">
            <div
              className={`relative ${className} ${
                (errors.email && touched.email && values.email !== "") ||
                (touched.email && error !== "")
                  ? "is-invalid"
                  : ""
              }`}
            >
              {(errors.email && touched.email && values.email !== "") ||
              (touched.email && error !== "") ? (
                <div className="absolute text-[#ff6161] opacity-70 text-[14px] -bottom-6">
                  {errors.email ?? error}
                </div>
              ) : null}
              <Field
                name="email"
                className={`${inputClass} emailform__input`}
                type="text"
                placeholder="E-Mail Address"
                onKeyUp={onChange}
              />
              <button
                type={"submit"}
                className={`emailform__btn ${btnClass}`}
                disabled={isSubmitting}
              >
                <span>
                  {isSubmitting ? <img src={Loader} alt="" /> : btnText}
                </span>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EmailForm;
