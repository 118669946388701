import { useRef } from "react";
import SuccessModalContainer from "./container";
import "./SuccessModal.scss";

const SuccessModal = ({ closeModalState, title, description }) => {
  const modalRef = useRef();
  const formRef = useRef();

  const closeModal = () => {
    modalRef.current.classList.add("fadeOut");
    modalRef.current.style.animationDelay = "0.3s";
  };

  const formZoomOut = (ref) => {
    ref.current.classList.add("zoomOut");
    ref.current.style.animationDelay = "0s";
  };

  const disposeModal = (formRef) => {
    closeModal();
    formZoomOut(formRef);
    closeModalState();
  };

  const displayHandle = (e) => {
    if (e.target.closest(".successModal-container") === null)
      disposeModal(formRef);
  };

  const scrollHandle = () => {
    if (modalRef.current) {
      disposeModal(formRef);
    }
  };

  window.addEventListener("scroll", scrollHandle);

  return (
    <>
      <div
        onClick={displayHandle}
        ref={modalRef}
        className="successModal animated fadeIn"
      >
        <SuccessModalContainer
          ref={formRef}
          disposeModal={disposeModal}
          title={title}
          description={description}
        />
      </div>
    </>
  );
};

export default SuccessModal;
