import SuccessCheckIcon from "../../assets/images/ic-modal-success.png";
import { forwardRef } from "react";

const SuccessModalContainer = ({ disposeModal, title, description }, ref) => {
  return (
    <>
      <div
        ref={ref}
        className="successModal__container animated zoomIn"
        style={{ animationDelay: "0.5s" }}
      >
        <button
          className="successModal__close"
          onClick={() => disposeModal(ref)}
        >
          <span></span>
          <span></span>
        </button>
        <h2 className="successModal__title">{title}</h2>
        <p className="successModal__description">{description}</p>
        <img src={SuccessCheckIcon} alt="" className="mx-auto my-8 w-[200px]" />
        <button
          className="successModal__send-btn"
          type="submit"
          onClick={() => disposeModal(ref)}
        >
          Done
        </button>
      </div>
    </>
  );
};

export default forwardRef(SuccessModalContainer);
