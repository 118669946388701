import "./assets/sass/global.scss";
import Wellcome from "./sections/Wellcome/Wellcome";

function App() {
  return (
    <>
      <Wellcome />
    </>
  );
}

export default App;
